import SubHeader from "@/components/common/SubHeader";
import LoadingBot from "@assets/imgs/book_vbot.webp";
import FirstLoadingBot from "@assets/imgs/search_vbot.webp";
import { useEffect, useState } from "react";
import AiLoading from "./AiLoading";

interface AiLoadingComponentProps {
  isFirst: boolean;
  handleModal: () => void;
}
const AiLoadingComponent = ({
  isFirst,
  handleModal,
}: AiLoadingComponentProps) => {
  const [isShowHeader, setIsShowHeader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowHeader(true);
    }, 10000);
  }, []);

  return (
    <div css={{ display: "fixed", top: 0, left: 0, zIndex: 9999 }}>
      {isShowHeader && (
        <SubHeader
          backFunc={handleModal}
          backText="처음으로"
          isShowHospital={false}
          css={{
            position: "relative",
            width: "100%",
            zIndex: 99999,
            padding: "12px 40px",
          }}
        />
      )}
      {/* 다음 질문시 로딩 아이콘 */}
      {!isFirst && (
        <AiLoading
          icon={
            <img css={{ width: "240px", height: "240px" }} src={LoadingBot} />
          }
          title="AI가 더 정확한<br />질문을 만들고 있어요."
        />
      )}

      {/* 첫 문항 보여주기전 로딩 아이콘 */}
      {isFirst && (
        <AiLoading
          icon={
            <img
              css={{
                width: "240px",
                height: "240px",
              }}
              src={FirstLoadingBot}
            />
          }
          title="정확한 진료를 위한<br />AI 맞춤 설문이 진행됩니다."
        />
      )}
    </div>
  );
};

export default AiLoadingComponent;
