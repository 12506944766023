import { NetworkManager } from "@/network-module/NetworkManager";
import styled from "@emotion/styled";
import { Dispatch, SetStateAction, useState } from "react";
import ScreenAdditionalPainInput from "./ScreenAdditionalPainInput";
import ScreenPainPanel from "./ScreenPainPanel";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "45%",
  overflow: "scroll",
});

const ScreenAdditionalPain = ({
  nextStep,
  departmentId,
  backFunc,
  isAdditionalPain,
  setIsAdditionalPain,
}: {
  nextStep: Function;
  departmentId: number;
  backFunc: Function;
  isAdditionalPain: boolean;
  setIsAdditionalPain: Dispatch<SetStateAction<boolean>>;
}) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { data: additionalPain } =
    NetworkManager.checkupService.getAdditionalPain(departmentId);

  const handleSelect = (id: number, idx: number) => {
    setSelectedId(id);
    setSelectedIndex(idx);
  };

  const handleConfirm = () => {
    const selectedAnswer = additionalPain?.screeningAnswer[selectedIndex];

    // 아픈부분이 추가로 있을때
    if (selectedAnswer.type === 1) {
      setIsAdditionalPain(true);
      return;
    }

    // 다른 아픈부분이 없을때
    let elapseData = {
      id: additionalPain?.id,
      title: additionalPain?.title,
      question: additionalPain?.question,
      answers: [
        {
          id: selectedAnswer?.id,
          content: selectedAnswer?.content,
        },
      ],
    };

    nextStep(elapseData);
  };
  return (
    <>
      {!isAdditionalPain && (
        <ScreenPainPanel
          additionalPain={additionalPain}
          selectedId={selectedId}
          handleSelect={handleSelect}
          handleConfirm={handleConfirm}
          backFunc={backFunc}
        />
      )}
      {isAdditionalPain && (
        <ScreenAdditionalPainInput
          backFunc={backFunc}
          nextStep={nextStep}
          additionalPain={additionalPain}
          selectedIndex={selectedIndex}
        />
      )}

      {/* <div
        style={{
          padding: "14px 24px",
          width: "100%",
          height: "10%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <BackButton type={BackBtnType.Gray} backFunc={backFunc} />
        </div>
        <HospitalName />
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          whiteSpace: "pre-wrap",
          fontSize: "clamp(2.5rem, 3.125vw, 6.40625rem)",
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "170%",
          letterSpacing: "-0.96px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <HighLight text="추가로" textColor="black" />
          <HighLight text="더 아프신 부분이 있나요?" />
        </div>
        <div
          style={{
            fontSize: "clamp(1.5rem, 1.875vw, 3.84375rem)",
            fontWeight: "400",
            lineHeight: "36px",
            textAlign: "center",
          }}
        ></div>
      </div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <List.newUnOrderList
            style={{
              display: "flex",
              overflow: "scroll",
              margin: "20px 0px",
              width: "100%",
              flexDirection: "column",
              justifyContent:
                additionalPain?.screeningAnswer.length ?? 0 < 3 ? "center" : "",
            }}
            items={additionalPain?.screeningAnswer ?? []}
            itemContainerStyle={() => {
              return {
                width: "100%",
                height: "fit-content",
              };
            }}
            itemTemplate={(item: any, index: number) => {
              return (
                <div
                  onClick={() => {
                    setSelectedId(item.id);
                    setSelectedIndex(index);
                  }}
                  css={{
                    margin: "10px 0px",
                    display: "flex",
                    width: "100%",
                    padding: "3% 5%",
                    borderRadius: "16px",
                    border:
                      selectedId == item.id
                        ? "2px solid #747DEF"
                        : "2px solid #DBDBDB",
                    gap: "6%",
                    fontSize: "clamp(1.5rem, 1.875vw, 3.84375rem)",
                    fontWeight: "bold",
                    justifyContent: "flex-content",
                    alignItems: "center",
                    background: selectedId == item.id ? "#E8EAFF" : "white",
                    color: selectedId == item.id ? "#5560EB" : "black",
                  }}
                >
                  <img
                    src={selectedId == item.id ? RadioEnable : RadioDisable}
                  />
                  <span>{item.content}</span>
                </div>
              );
            }}
          />
        </div>
      </Container>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
      >
        <SquareButton
          style={{
            padding: "20px 20px",
            background:
              selectedId == 0
                ? "var(--new-fa-patient-btn-g-003, #CDCDCD)"
                : "var(--new-fa-patient-fa-primary-100, #5560EB)",
          }}
          onClick={() => {
            if (selectedId == 0) {
              return;
            }
            let elapseData = {
              id: additionalPain?.id,
              title: additionalPain?.title,
              question: additionalPain?.question,
              answers: [
                {
                  id: additionalPain?.screeningAnswer[selectedIndex].id,
                  content:
                    additionalPain?.screeningAnswer[selectedIndex].content,
                },
              ],
            };
            nextStep(elapseData);
          }}
          label="선택 완료"
          buttonType={"cancel"}
          labelStyle={{
            fontWeight: "600",
            fontSize: "clamp(1.5rem, 1.875vw, 3.84375rem)",
          }}
        />
      </div> */}
    </>
  );
};
export default ScreenAdditionalPain;
