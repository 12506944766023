import NewLayout from "@/components/common/NewLayout";
import { NetworkManager } from "@/network-module/NetworkManager";
import {
  BadIcon,
  LittlegoodIcon,
  NochangeIcon,
  VerygoodIcon,
} from "@/theme/CustomIcons";
import { useState } from "react";
import {
  Button,
  COLORS,
  Title,
  typography_style_heading_md_semilbold,
} from "vd-component";

const ICON_LIST = [
  <VerygoodIcon />,
  <LittlegoodIcon />,
  <NochangeIcon />,
  <BadIcon />,
];
const ScreenCureElapse = ({
  nextStep,
  departmentId,
  backFunc,
}: {
  nextStep: Function;
  departmentId: number;
  backFunc: Function;
}) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { data: elapse } =
    NetworkManager.checkupService.getElapse(departmentId);

  const handleSelect = (id: number, idx: number) => {
    setSelectedId(id);
    setSelectedIndex(idx);
  };

  const handleNextStep = () => {
    if (selectedId == 0) {
      return;
    }
    let elapseData = {
      id: elapse?.id,
      title: elapse?.title,
      question: elapse?.question,
      answers: [
        {
          id: elapse?.screeningAnswer[selectedIndex].id,
          content: elapse?.screeningAnswer[selectedIndex].content,
        },
      ],
    };
    nextStep(elapseData);
  };

  return (
    <>
      <NewLayout
        backFunc={backFunc}
        isShowPatientInfo={true}
        css={{
          padding: "52.5px 57.5px",
          flexDirection: "column",
          gap: "24px",
          justifyContent: "normal",
        }}
      >
        <Title
          size="2x-large"
          title="진료 후 경과가 어떠셨나요?"
          highlightArr={["경과"]}
          description="약물, 물리치료 등 치료 후 상태를 알려주세요. "
          css={{ alignItems: "flex-start" }}
        />
        <div css={{ display: "flex", gap: "20px", minHeight: "164px" }}>
          {elapse?.screeningAnswer.map((item, idx) => {
            const isSelected = selectedId == item.id;
            return (
              <div
                key={item.id}
                css={{
                  display: "flex",
                  flex: 1,
                  height: "164px",
                  flexDirection: "column",
                  gap: "12px",
                  borderRadius: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  border: isSelected ? "2px solid" : "1px solid",
                  borderColor: isSelected
                    ? COLORS.colorInversePrimaryNomal
                    : COLORS.colorLineLight,
                  padding: "24px 8px",
                  boxShadow: isSelected
                    ? "0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 16px 20px 0px rgba(0, 0, 0, 0.12)"
                    : "none",
                }}
                onClick={() => handleSelect(item.id, idx)}
              >
                <div css={{ minWidth: "64px", minHeight: "57px" }}>
                  {ICON_LIST[idx]}
                </div>
                <span
                  css={[
                    typography_style_heading_md_semilbold,
                    { textAlign: "center", wordBreak: "keep-all" },
                  ]}
                >
                  {item.content}
                </span>
              </div>
            );
          })}
        </div>
        <div css={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="x-large"
            css={{ width: "397px" }}
            disabled={selectedId === 0}
            onClick={handleNextStep}
          >
            확인
          </Button>
        </div>
      </NewLayout>
      {/* <div
        style={{
          padding: "14px 24px",
          width: "100%",
          height: "10%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <BackButton type={BackBtnType.Gray} backFunc={backFunc} />
        </div>
        <HospitalName />
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          whiteSpace: "pre-wrap",
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "170%",
          letterSpacing: "-0.96px",
        }}
      >
        <div>
          <HighLightComplex
            hlArr={["치료를 받고"]}
            hlStr="{0} 경과가 어떠셨나요?"
          />
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "36px",
            textAlign: "center",
            color: "#999",
          }}
        >
          약물, 물리치료 등 치료 후 상태를 알려주세요.
        </div>
      </div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <List.newUnOrderList
            style={{
              display: "flex",
              overflow: "scroll",
              margin: "20px 0px",
              width: "100%",
              flexDirection: "column",
              justifyContent:
                elapse?.screeningAnswer.length ?? 0 < 3 ? "center" : "",
            }}
            items={elapse?.screeningAnswer ?? []}
            itemContainerStyle={() => {
              return {
                width: "100%",
                height: "fit-content",
              };
            }}
            itemTemplate={(item: any, index: number) => {
              return (
                <div
                  onClick={() => {
                    setSelectedId(item.id);
                    setSelectedIndex(index);
                  }}
                  css={{
                    margin: "10px 0px",
                    display: "flex",
                    width: "100%",
                    padding: "3% 5%",
                    borderRadius: "16px",
                    border:
                      selectedId == item.id
                        ? "2px solid #747DEF"
                        : "2px solid #DBDBDB",
                    gap: "6%",
                    fontSize: "20px",
                    fontWeight: "bold",
                    justifyContent: "flex-content",
                    alignItems: "center",
                    background: selectedId == item.id ? "#E8EAFF" : "white",
                    color: selectedId == item.id ? "#5560EB" : "black",
                  }}
                >
                  <img
                    width={32}
                    height={32}
                    src={selectedId == item.id ? RadioEnable : RadioDisable}
                  />
                  <span>{item.content}</span>
                </div>
              );
            }}
          />
        </div>
      </Container>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
      >
        <SquareButton
          style={{
            padding: "20px 111.5px",
            background:
              selectedId == 0
                ? "var(--new-fa-patient-btn-g-003, #CDCDCD)"
                : "var(--new-fa-patient-fa-primary-100, #5560EB)",
          }}
          onClick={() => {
            if (selectedId == 0) {
              return;
            }
            let elapseData = {
              id: elapse?.id,
              title: elapse?.title,
              question: elapse?.question,
              answers: [
                {
                  id: elapse?.screeningAnswer[selectedIndex].id,
                  content: elapse?.screeningAnswer[selectedIndex].content,
                },
              ],
            };
            nextStep(elapseData);
          }}
          label="선택 완료"
          buttonType={"cancel"}
          labelStyle={{
            fontWeight: "600",
            fontSize: "clamp(1.5rem, 1.875vw, 3.84375rem)",
          }}
        />
      </div> */}
    </>
  );
};
export default ScreenCureElapse;
