import NewLayout from "@/components/common/NewLayout";
import { ScreenPainExplain } from "@/features/PainExplain/components/ScreenPainExplain";
import { NetworkManager } from "@/network-module/NetworkManager";
import { CancelIcon, CheckNormalIcon } from "@/theme/CustomIcons";

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, COLORS, SelectChip, Title } from "vd-component";
import ReceptionBackHomePopup from "../Reception/components/ReceptionBackHomePopup";

type SymptomState = {
  id: number;
  content: string;
  checked: boolean;
};

const ScreenSelectMainSymptom = ({
  departmentId,
  setMainSymptomData,
  backFunc,
}: {
  departmentId: number;
  setMainSymptomData: Function;
  backFunc: Function;
}) => {
  const navigate = useNavigate();
  const [symptomStep, setSymptomStep] = useState(0);
  const [directInput, setDirectInput] = useState("");
  const [directChecked, setDirectChecked] = useState<boolean>(false);
  const [isOpenBackHome, setIsOpenBackHome] = useState(false);
  const { data: mainSymptom } =
    NetworkManager.checkupService.getSickWhere(departmentId);

  const [symptomState, setSymptomState] = useState<SymptomState[]>([]);

  const isValid = useMemo(() => {
    return symptomState.some((v) => v.checked) || directChecked;
  }, [symptomState, directChecked]);

  useEffect(() => {
    if (mainSymptom?.screeningAnswer) {
      setSymptomState(() => {
        return mainSymptom.screeningAnswer.map((v) => {
          return {
            id: v.id,
            content: v.content,
            checked: false,
          };
        });
      });
    }
  }, [mainSymptom]);

  const makeAnswer = (): { id: number | null; content: string }[] => {
    let symptomData = new Array();
    symptomState.map((sv) => {
      if (sv.checked) {
        symptomData.push({
          id: sv.id,
          content: sv.content,
        });
      }
    });
    return symptomData;
  };

  const makeMainSymptomData = (answers: Array<any>) => {
    return {
      id: mainSymptom?.id,
      title: "main_symptom",
      question: mainSymptom?.question,
      answers: answers,
    };
  };

  const handleBack = () => {
    setIsOpenBackHome(true);
  };

  if (symptomStep == 0) {
    return (
      <NewLayout
        backFunc={handleBack}
        backText="처음으로"
        isShowPatientInfo={true}
        css={{
          flexDirection: "column",
          padding: "36px 48px",
          gap: "55px",
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Title
            size="2x-large"
            title="{0}을 선택해 주세요"
            description="근접한 증상을 모두 선택해 주세요."
            highlightArr={["주 증상"]}
            css={{ alignItems: "flex-start" }}
            descriptionStyleProps={{ color: COLORS.colorLabelLight }}
          />
          <div css={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
            {symptomState.map((sv, sk) => {
              return (
                <SelectChip
                  key={sk}
                  chipType={
                    symptomState[sk].checked ? "selected" : "unselected"
                  }
                  size="xlarge"
                  leftIcon={
                    <CheckNormalIcon
                      color={
                        symptomState[sk].checked
                          ? COLORS.colorInverseLabelLight
                          : COLORS.colorLabelNomal
                      }
                    />
                  }
                  variant="secondary"
                  css={{ borderRadius: "999px" }}
                  onClick={() => {
                    setSymptomState(
                      symptomState.map((symptom) =>
                        symptom.id === sv.id
                          ? { ...symptom, checked: !sv.checked }
                          : symptom,
                      ),
                    );
                  }}
                >
                  {sv.content}
                </SelectChip>
              );
            })}
            <SelectChip
              chipType={directChecked ? "selected" : "unselected"}
              size="xlarge"
              leftIcon={
                <CheckNormalIcon
                  color={
                    directChecked
                      ? COLORS.colorInverseLabelLight
                      : COLORS.colorLabelNomal
                  }
                />
              }
              variant="secondary"
              css={{ borderRadius: "999px" }}
              onClick={() => {
                setDirectChecked(!directChecked);
              }}
            >
              {directInput.length > 10
                ? directInput.slice(0, 10) + "..."
                : directInput || "직접입력"}
              {directInput && (
                <CancelIcon
                  color={COLORS.colorLabelAlternative}
                  onClick={() => {
                    setDirectInput("");
                  }}
                />
              )}
            </SelectChip>
          </div>
        </div>

        <div css={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="x-large"
            css={{ width: "397px" }}
            disabled={!isValid}
            onClick={() => {
              if (directChecked && directInput === "") {
                setSymptomStep(1);
                return;
              }
              if (isValid) {
                let mainSymptomArr = makeAnswer();
                if (directInput != "") {
                  mainSymptomArr.push({
                    id: null,
                    content: directInput,
                  });
                }
                setMainSymptomData(makeMainSymptomData(mainSymptomArr), true);
              }
            }}
          >
            확인
          </Button>
        </div>
        <ReceptionBackHomePopup
          isOpen={isOpenBackHome}
          onClose={() => setIsOpenBackHome(false)}
          handleClick={() => backFunc()}
        />
      </NewLayout>
    );
  }

  if (symptomStep == 1) {
    return (
      <ScreenPainExplain
        keywords={symptomState.filter((v) => v.checked).map((v) => v.content)}
        backFunc={() => {
          setSymptomStep(0);
        }}
        makeAnswer={makeAnswer()}
        setPainExplain={(detailSymptom: string) => {
          setDirectInput(detailSymptom);
          setSymptomStep(0);
        }}
      />
    );
  }
  navigate("/checkup");
};

export default ScreenSelectMainSymptom;
