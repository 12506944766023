import { Dispatch, SetStateAction, useRef } from "react";
import { Button, ButtonContainer, Popup, Title } from "vd-component";

interface BackPrevModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  prevFunc: () => void;
}
const BackPrevModal = ({ isOpen, setIsOpen, prevFunc }: BackPrevModalProps) => {
  const isLoadingRef = useRef(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(true);
      }}
      css={{
        padding: "24px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        zIndex: 3,
      }}
      backdropStyles={{ zIndex: 3 }}
    >
      <Title
        size="x-large"
        title="이전 질문으로</br> 돌아가시겠습니까?"
        description="선택했던 항목을 수정할 수 있어요."
        css={{ textAlign: "center" }}
      />
      <ButtonContainer css={{ width: "360px" }}>
        <Button
          size="large"
          variant="secondary-line"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          취소
        </Button>
        <Button
          size="large"
          variant="primary"
          onClick={async () => {
            if (isLoadingRef.current) {
              return;
            }
            isLoadingRef.current = true;
            await prevFunc();
            isLoadingRef.current = false;
          }}
        >
          확인
        </Button>
      </ButtonContainer>
    </Popup>
  );
};

export default BackPrevModal;
