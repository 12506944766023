import { usePatientDataStore } from "@/features/Screen/store/ScreenStore";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScreenReusePage from "./ScreenReusePage";
import ScreenUsePage from "./ScreenUsePage";

const ScreenPage = () => {
  const queryClient = useQueryClient();

  const patientData = usePatientDataStore.getState();

  const navigate = useNavigate();
  queryClient.removeQueries({ queryKey: ["getSickWhere", "getSickPeriod"] });

  useEffect(() => {
    if (patientData.checkupId === 0) return navigate("/");
  }, [patientData.checkupId]);

  return patientData.relatedPreClinic !== null ? (
    // 재진
    <ScreenReusePage patientData={patientData} />
  ) : (
    // 새로운 진료
    <ScreenUsePage patientData={patientData} />
  );
};

export default ScreenPage;
