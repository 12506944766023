import SubHeader from "@/components/common/SubHeader";

import { SubmitType } from "@/pages/screening/ScreenUsePage";
import { useState } from "react";
import "regenerator-runtime";
import "regenerator-runtime/runtime";
import { Button, COLORS, Title } from "vd-component";
import { usePhase3Hook } from "../store/Phase3Hook";
import AiBackLoadingComponent from "./AiBackLoadingComponent";
import AiBg from "./AiBg";
import AiLoadingComponent from "./AiLoadingComponent";
import { AIQuestionCustomTextarea } from "./AIQuestionCustomTextarea";
import BackPrevModal from "./BackPrevModal";
import ErrorGoToFirstModal from "./ErrorGoToFirstModal";
import GoToFirstModal from "./GoToFirstModal";
import Phase3QuestionContainer from "./Phase3QuestionContainer";
import PrograssBar from "./PrograssBar";

const ScreenAIQuestionPage = ({
  submitPatientData,
  nextStep,
}: {
  submitPatientData: SubmitType;
  nextStep: Function;
}) => {
  const {
    showDim,
    showBackDim,
    question,
    answerState,
    toggleAnswer,
    checked,
    submit,
    historyEnd,
    streamIsFinal,
    isFirst,
    sequence,
    customInputText,
    setCustomInputText,
    errorModalOpen,
    setErroModalOpen,
    backStep,
  } = usePhase3Hook(submitPatientData);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowInput, setIsShowInput] = useState<boolean>(false);
  const [isPrevModalOpen, setIsPrevModalOpen] = useState<boolean>(false);

  if (historyEnd) {
    nextStep(submitPatientData.checkUpId);
  }

  const isActiveNextButton =
    streamIsFinal && (checked || customInputText !== "");

  return (
    <>
      <AiBg />

      {showDim && (
        <AiLoadingComponent
          isFirst={isFirst}
          handleModal={() => setIsOpen(true)}
        />
      )}
      {showBackDim && (
        <AiBackLoadingComponent handleModal={() => setIsOpen(true)} />
      )}

      {!showDim && (
        <div css={{ height: "100%", zIndex: 2 }}>
          <SubHeader
            backFunc={() => {
              setIsOpen(true);
            }}
            isShowPatientInfo
            mode="dark"
            backText="처음으로"
            css={{ padding: "12px 40px", marginBottom: "24px" }}
          />
          <PrograssBar sequence={sequence} />
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              padding: "24px 40px",
              gap: "24px",
              height: "calc(100% - 94px)",
              justifyContent: "space-between",
            }}
          >
            <div
              css={{ display: "flex", flexDirection: "column", gap: "24px" }}
            >
              <Title
                size="2x-large"
                title={question}
                description={question && "근접한 문항을 모두 선택해 주세요"}
                css={{
                  color: COLORS.colorInverseLabelLight,
                  alignItems: "flex-start",
                  whiteSpace: "pre-wrap",
                  wordBreak: "keep-all",
                }}
                descriptionStyleProps={{ color: COLORS.colorInverseLabelNomal }}
              />
              {/* 답안들 */}
              <Phase3QuestionContainer
                toggleAnswer={toggleAnswer}
                answerState={answerState}
                setDefaultText={() => {
                  setCustomInputText("");
                }}
                setIsShowInput={setIsShowInput}
                defaultText={customInputText}
                streamIsFinal={streamIsFinal}
              />
            </div>

            {/* 하단 버튼 */}
            <div
              css={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
            >
              <Button
                size="x-large"
                variant="dark"
                css={{
                  display: sequence != 1 ? "block" : "none",
                  width: "194px",
                  height: "68px",
                  zIndex: 9,
                  position: "relative",
                }}
                disabled={!streamIsFinal}
                onClick={() => {
                  setIsPrevModalOpen(true);
                }}
              >
                뒤로
              </Button>
              <Button
                size="x-large"
                variant="secondary-line"
                css={{
                  width: "194px",
                  height: "68px",
                  zIndex: 9,
                  position: "relative",
                }}
                disabled={!isActiveNextButton}
                onClick={() => {
                  if (!isActiveNextButton) return;
                  submit(customInputText);
                }}
              >
                다음
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* 직접입력누르면 나오는 textarea */}
      {isShowInput && (
        <AIQuestionCustomTextarea
          questionTitle={question}
          isShowInput={isShowInput}
          setIsShowInput={setIsShowInput}
          customInputText={customInputText}
          setCustomInputText={setCustomInputText}
        />
      )}
      {/* 처음으로 모달 */}
      <GoToFirstModal
        checkupId={submitPatientData.checkUpId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <ErrorGoToFirstModal
        checkupId={submitPatientData.checkUpId}
        isOpen={errorModalOpen}
        setIsOpen={setErroModalOpen}
      />
      <BackPrevModal
        isOpen={isPrevModalOpen}
        setIsOpen={setIsPrevModalOpen}
        prevFunc={() => {
          backStep();
          setIsPrevModalOpen(false);
        }}
      />
    </>
  );
};
export default ScreenAIQuestionPage;
