import NewLayout from "@/components/common/NewLayout";
import { GetPain } from "@/network-module/entities/CheckupEntityNew";
import { NopainIcon, PainIcon } from "@/theme/CustomIcons";
import {
  Button,
  COLORS,
  Title,
  typography_style_heading_md_semilbold,
} from "vd-component";

interface ScreenPainPanelProps {
  additionalPain: GetPain;
  selectedId: number;
  handleSelect: (id: number, idx: number) => void;
  handleConfirm: () => void;
  backFunc: Function;
}
const ICON_LIST = [<PainIcon />, <NopainIcon />];
const ScreenPainPanel = ({
  additionalPain,
  selectedId,
  handleSelect,
  handleConfirm,
  backFunc,
}: ScreenPainPanelProps) => {
  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo={true}
      css={{
        padding: "70px 58px",
        justifyContent: "normal",
      }}
    >
      <div
        css={{
          width: "100%",

          display: "flex",
          flexDirection: "column",
          gap: "24px",
          justifyContent: "normal",
        }}
      >
        <Title
          size="2x-large"
          title="추가로 진료가 필요한 새로운 증상이 있나요?"
        />
        <div css={{ display: "flex", gap: "20px", minHeight: "164px" }}>
          {additionalPain?.screeningAnswer?.map((item, idx) => {
            const isSelected = selectedId === item.id;
            return (
              <div
                key={item.id}
                css={{
                  display: "flex",
                  flex: 1,
                  height: "164px",
                  flexDirection: "column",
                  gap: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "24px 20px",
                  borderRadius: "12px",
                  border: isSelected ? "2px solid" : "1px solid",
                  borderColor: isSelected
                    ? COLORS.colorInversePrimaryNomal
                    : COLORS.colorLineNomal,
                  boxShadow: isSelected
                    ? "0 0 8px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 16px 20px 0 rgba(0, 0, 0, 0.12)"
                    : "none",
                }}
                onClick={() => handleSelect(item.id, idx)}
              >
                {ICON_LIST[idx]}
                <span css={[typography_style_heading_md_semilbold]}>
                  {item.content}
                </span>
              </div>
            );
          })}
        </div>
        <div css={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="x-large"
            css={{ width: "397px" }}
            disabled={selectedId === 0}
            onClick={handleConfirm}
          >
            확인
          </Button>
        </div>
      </div>
    </NewLayout>
  );
};

export default ScreenPainPanel;
