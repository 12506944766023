import { useStomp } from "@/network-module/context/StompContext";
import { NetworkManager } from "@/network-module/NetworkManager";
import { SubmitType } from "@/pages/screening/ScreenUsePage";
import { useEffect, useReducer, useState } from "react";

import "regenerator-runtime";
import "regenerator-runtime/runtime";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export type AnswerState = { keyword: string; type: number; checked: boolean };

const singleSelectArray = ["없음", "아니오", "아니요", "해당 없음", "해당없음"];
let isEndFirstQuestion = false;
export function usePhase3Hook(submitPatientData: SubmitType) {
  const [errorModalOpen, setErroModalOpen] = useState<boolean>(false);

  const { websocket } = useStomp();

  const [streamIsFinal, setStreamIsFinal] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isLoad, setIsLoad] = useState(false);

  const [checked, setChecked] = useState(false);
  const [historyEnd, setHistoryEnd] = useState(false);

  const [historyId, setHistoryId] = useState(0);
  const [userAnswer, setUserAnswer] = useState("[]");
  const [sequence, setSequence] = useState(0);
  const [question, setQuestion] = useState("");

  const [showDim, setShowDim] = useState(true);
  const [showBackDim, setShowBackDim] = useState(false);

  const [answerState, editAnswerState] = useState<AnswerState[]>([]);

  const [isCustomInput, toggleIsCustomInput] = useReducer(
    (state) => !state,
    false,
  );

  const [customInputText, setCustomInputText] = useState("");

  const backStep = () => {
    setShowBackDim(true);
    NetworkManager.checkupService
      .backStep(submitPatientData.checkUpId, sequence)
      .then((res) => {
        setSequence((prevSeq) => prevSeq - 1);

        setQuestion((prev) => {
          const parseKeyword = res.keywordList;
          const checkedKeyword = res.answerList;

          let prevCustomText = "";

          const editKeyword = parseKeyword
            .filter(
              (keyword: string) =>
                keyword !== "직접 입력" && keyword !== "직접입력",
            )
            .map((keyword: string) => {
              return {
                keyword: keyword,
                type: singleSelectArray.includes(keyword) ? 2 : 0,
                checked: checkedKeyword.includes(keyword),
              };
            });

          checkedKeyword.forEach((keyword: string) => {
            if (!parseKeyword.includes(keyword)) {
              prevCustomText = keyword;
            }
          });
          setCustomInputText(prevCustomText);

          editAnswerState(editKeyword);

          setStreamIsFinal(true);
          setChecked(true);
          setShowDim(false);
          setIsFirst(false);
          setHistoryEnd(false);

          setShowBackDim(false);

          return res.question;
        });
      })
      .catch(() => {
        setShowBackDim(false);
      });
  };

  const makeUserAnswerForm = (text: string) => {
    if (!Array.isArray(answerState)) {
      return false;
    }

    let answer = answerState
      .filter((answer) => answer.checked)
      .map((state) => {
        return state.keyword;
      });

    if (text != "") {
      answer.push(text);
    }
    return JSON.stringify(answer);
  };

  const onRelisten = () => {
    const customEvent = new CustomEvent("restart", {
      detail: { message: "retart listenling" },
    });

    document.dispatchEvent(customEvent);
  };

  const toggleAnswer = (current: number, offOtherAnswer: boolean) => {
    if (!Array.isArray(answerState) || !streamIsFinal) {
      return;
    }

    let checked = false;
    let tmpAnswer = answerState.map((v: any, k: number) => {
      if (k == current) {
        v = { ...v, checked: !v.checked };
      } else if (offOtherAnswer || v.type == 2) {
        v = { ...v, checked: false };
      }

      if (v.checked) {
        checked = true;
      }

      return v;
    });

    setChecked(checked);
    editAnswerState(tmpAnswer);
  };

  const phaseCheck = async () => {
    if (isEndFirstQuestion) return;
    await NetworkManager.checkupService
      .phaseCheck({
        checkUpId: submitPatientData.checkUpId,
        answer: {
          historyId: historyId,
          userAnswer: userAnswer,
          sequence: sequence,
        },
      })
      .then(async (res) => {
        if (res.length == 0) {
          setTimeout(() => {
            phaseCheck();
          }, 1000);
        } else {
          if (isEndFirstQuestion) return;
          setHistoryId(res[0].id);
          setSequence(res[0].sequence);

          setQuestion((prev) => {
            if (!prev) {
              setHistoryEnd(res[0].historyEnd);

              const parseKeyword = JSON.parse(res[0].keyword);

              const editKeyword = parseKeyword
                .filter(
                  (keyword: string) =>
                    keyword !== "직접 입력" && keyword !== "직접입력",
                )
                .map((keyword: string) => {
                  return {
                    keyword: keyword,
                    type: singleSelectArray.includes(keyword) ? 2 : 0,
                    checked: false,
                  };
                });

              editAnswerState(editKeyword);

              setStreamIsFinal(true);
              setShowDim(false);
              setIsFirst(false);

              return res[0].llmQuestion;
            } else {
              return prev;
            }
          });
        }
      })
      .catch((err) => {});
  };

  const submitTwo = async () => {
    try {
      const submitTwoRes = await NetworkManager.checkupService.submitTwo({
        checkUpId: submitPatientData.checkUpId,
        answer: {
          historyId: historyId,
          userAnswer: userAnswer,
          sequence: sequence,
        },
      });

      if (submitTwoRes) {
        await phaseCheckRecursive();
        setUserAnswer("[]");
        setCustomInputText("");
      }
    } catch (err) {
      setShowDim(true);
      setErroModalOpen(true);
    }
  };

  const submit = (customText: string) => {
    let answer;
    if (customText != "") {
      answer = makeUserAnswerForm(customText);
    } else {
      answer = makeUserAnswerForm("");
    }

    setUserAnswer(answer);
  };

  useEffect(() => {
    if (!isFirst && userAnswer != "[]") {
      setChecked(false);
      setShowDim(true);
      setQuestion("");
      editAnswerState([]);
      setPhaseCheckRes(null);
      setStreamIsFinal(false);
      isEndFirstQuestion = true;
    }
  }, [userAnswer]);

  useEffect(() => {
    if (question === "" && answerState.length === 0 && isLoad) {
      submitTwo();
    }
  }, [question, answerState, isLoad]);

  useEffect(() => {
    if (question && !isLoad) {
      setIsLoad(true);
    }
  }, [question, isLoad]);

  const [phaseCheckRes, setPhaseCheckRes] = useState<any>(null);
  const phaseCheckRecursive = async () => {
    const phaseCheckRes = await NetworkManager.checkupService.phaseCheck({
      checkUpId: submitPatientData.checkUpId,
      answer: {
        historyId: historyId,
        userAnswer: userAnswer,
        sequence: sequence,
      },
    });
    setPhaseCheckRes(phaseCheckRes);
  };

  useEffect(() => {
    if (!phaseCheckRes) return;

    const handlePhaseCheck = async () => {
      await delay(1000);
      phaseCheckRecursive();
    };

    if (phaseCheckRes?.length == 0) {
      handlePhaseCheck();
    } else {
      setUserAnswer("[]");

      // 최신 상태 확인 가능
      setHistoryId(phaseCheckRes[0].id);
      setSequence(phaseCheckRes[0].sequence);

      if (!question) {
        setHistoryEnd(phaseCheckRes[0].historyEnd);

        setQuestion(phaseCheckRes[0].llmQuestion);

        const parseKeyword = JSON.parse(phaseCheckRes[0].keyword);
        const editKeyword = parseKeyword
          .filter(
            (keyword: string) =>
              keyword !== "직접 입력" && keyword !== "직접입력",
          )
          .map((keyword: string) => {
            return {
              keyword: keyword,
              type: singleSelectArray.includes(keyword) ? 2 : 0,
              checked: false,
            };
          });
        editAnswerState(editKeyword);

        setStreamIsFinal(true);
        setShowDim(false);
        setIsFirst(false);
      }
    }
  }, [phaseCheckRes]);

  useEffect(() => {
    websocket.unsubscribe("phasetwo");
    websocket.subscribe(
      `/mobile/${submitPatientData.checkUpId}`,
      (message) => {
        if (streamIsFinal) return;
        const msgBody = JSON.parse(message.body);

        if (msgBody.type == "StreamTalk") {
          setShowDim(false);
        }
        if (msgBody.type == "IsFinal") {
          setSequence(msgBody.sequence);
          if (msgBody.is_final) {
            setHistoryEnd(true);
          }
        } else if (msgBody.type == "StreamTalk") {
          if (msgBody.word_type == "Q") {
            setQuestion((q) => {
              return q + msgBody.word;
            });
          }

          if (msgBody.word_type == "K") {
            if (msgBody.word !== "직접 입력" && msgBody.word !== "직접입력") {
              editAnswerState((prevAnswer) => [
                ...prevAnswer,
                {
                  keyword: msgBody.word,
                  type: singleSelectArray.includes(msgBody.word) ? 2 : 0,
                  checked: false,
                },
              ]);
            }

            if (msgBody.stream_is_final) {
              setPhaseCheckRes(null);
              setStreamIsFinal(true);
              setShowDim(false);
              setIsFirst(false);
            }
            return;
          }
        }
      },
      "phasetwo",
      () => {
        setTimeout(() => {
          NetworkManager.checkupService
            .submitOne(submitPatientData)
            .then((res) => {
              phaseCheck();
            });
        }, 1000);
      },
    );
  }, []);

  return {
    showDim,
    isCustomInput,
    toggleIsCustomInput,
    question,
    answerState,
    toggleAnswer,
    onRelisten,
    checked,
    submit,
    isFirst,
    streamIsFinal,
    historyEnd,
    sequence,
    customInputText,
    setCustomInputText,
    errorModalOpen,
    setErroModalOpen,
    backStep,
    showBackDim,
  };
}
