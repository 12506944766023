// import LoadingBot from "@assets/imgs/book_vbot.png";
import { useEffect } from "react";
// import AiLoading from "./AiLoading";

interface AiBackLoadingComponent {
  handleModal: () => void;
}
const AiBackLoadingComponent = ({ handleModal }: AiBackLoadingComponent) => {
  // const [isShowHeader, setIsShowHeader] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
    //   setIsShowHeader(true);
    // }, 10000);
  }, []);

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        background: "black",
        position: "fixed",
        zIndex: 9999,
      }}
    >
      {/* {isShowHeader && (
        <SubHeader
          backFunc={handleModal}
          backText="처음으로"
          isShowHospital={false}
          css={{
            position: "relative",
            width: "100%",
            zIndex: 99999,
            padding: "12px 40px",
          }}
        />
      )} */}

      {/* <AiLoading
        icon={
          <img css={{ width: "240px", height: "240px" }} src={LoadingBot} />
        }
        title="이전 질문을 불러오고 있습니다."
      /> */}
    </div>
  );
};

export default AiBackLoadingComponent;
